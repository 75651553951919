/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')
import $ from 'jquery'
import jQuery from 'jquery'
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import cable from 'actioncable';

// images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// style sheets
import '../stylesheets/application.scss';

// foundation sites 6
import foundation from 'foundation-sites';
import '../javascripts/foundation_startup.js';

// import "controllers"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("controllers", true, /.js$/)

// Turbolinks

application.load(definitionsFromContext(context))
Turbolinks.start()
