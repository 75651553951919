// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import $ from 'jquery';
import jQuery from 'jquery';

export default class extends Controller {
  static targets = [ "product", "about" ]

  navProduct() {
  	this.moveIt(this.productTarget)
  }

  navAbout() {
  	this.moveIt(this.aboutTarget)
  }

  moveIt(place) {
  	$('html, body').stop().animate({
        scrollTop: parseInt($(place).offset().top)
    }, 2000);
  }

}
