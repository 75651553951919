import { Controller } from "stimulus"
import $ from 'jquery';
import jQuery from 'jquery';

export default class extends Controller {
  static targets = [  ]

  initialize() {
		this.gameRunning = false;
		this.currentDiv = null;
		this.oldDiv = null;
		this.leftButtonDown = false;
		this.rightButtonDown = false;
		this.bothButtonDown = false;
		this.bombCount = 18;
		this.startTime = new Date();
		this.timerInterval = null;
		this.difficulty = 0;
		this.settings = [10,10,18];
		this.minefield = [];
		this.colorKey = {
			'':'#000000',
			1:'#0100FE',
			2:'#017F01',
			3:'#FE0000',
			4:'#010080',
			5:'#810102',
			6:'#008081',
			7:'#000000',
			8:'#808080',
		}
		this.setControls()
		this.lockInputs()
		this.createTheField()
		this.emojiState = {
			'happy':':)',
			'scared':'8O',
			'cool':'B)',
			'dead':'X)'
		}
  }

  connect() {
    console.log('hello from p-everywhere')
  }

  ///////////////////////////
  /////  Settings  //////////
  ///////////////////////////

  showSettings() {
  	this.showDiv($("#settings"))
  	this.hideDiv($("#settings_show_button"))
  	this.showDiv($("#settings_hide_button"))
  }

  hideSettings() {
  	this.hideDiv($("#settings"))
  	this.showDiv($("#settings_show_button"))
  	this.hideDiv($("#settings_hide_button"))
  }

	setSettingsView(newSettings) {
		$('#height').val(newSettings[0]);
		$('#width').val(newSettings[1]);
		$('#number_of_bombs').val(newSettings[2]);
		this.settings = newSettings;
	};

	setSettingSmall(event) {
		this.lockInputs();
		this.activateSettingsButton($(event.currentTarget));
		this.setSettingsView([10,10,18]);
	};

	setSettingMedium(event) {
		this.lockInputs();
		this.activateSettingsButton($(event.currentTarget));
		this.setSettingsView([15,20,60]);
	};

	setSettingLarge(event) {
		this.lockInputs();
		this.activateSettingsButton($(event.currentTarget));
		this.setSettingsView([20,30,113]);
	};

	setSettingCustom(event) {
		this.activateSettingsButton($(event.currentTarget));
		this.freeInputs();
	};

	activateSettingsButton(current_button) {
		current_button.siblings().removeClass('success');
		current_button.addClass('success');
	};

  hideDiv(divToHide) {
  	divToHide.addClass("hidden")
  }

  showDiv(divToShow) {
  	divToShow.removeClass("hidden")
  }

  lockInputs() {
		$('#height').prop('disabled', true);
		$('#width').prop('disabled', true);
		$('#number_of_bombs').prop('disabled', true);
	};

	freeInputs() {
		$('#height').prop('disabled', false);
		$('#width').prop('disabled', false);
		$('#number_of_bombs').prop('disabled', false);
	};

	readSettings() {
		console.log($('#height').val())
		this.settings[0] = $('#height').val();
		this.settings[1] = $('#width').val();
		this.settings[2] = $('#number_of_bombs').val();
	};

  ///////////////////////////
  /////  New Game  //////////
  ///////////////////////////

  createTheField() {
		this.readSettings();
		this.minefield = [];
		$('#minefield').empty();
		let sectors = [];
		this.bombCount = this.settings[2];
		let newBombCount = this.settings[2];
		for (let i = 0; i < this.settings[0]*this.settings[1]; i++) {
			sectors.push({
				isBomb : this.bombCounter(newBombCount),
				isFlagged : 0,
				opened : false,
				bombNear : null,
				x : null,
				y : null
			})
			newBombCount--;
		};
		sectors.sort(function() {
		  return .5 - Math.random();
		});
		for (let i = 0; i < this.settings[0]; i++) {
			this.minefield.push([]);
			$('#minefield').append($('#grid-x').html());
			for (let k = 0; k < this.settings[1]; k++) {
				let newSector = sectors.pop();
				newSector.x = i;
				newSector.y = k;
				this.minefield[i].push(newSector);
				let sectorDiv = $('<div />', {
	        "class": 'sector',
	        "x": i.toString(),
	        "y": k.toString(),
	        "data-action": "mouseover->p-everywhere#mouseOverCheck mousedown->p-everywhere#mouseDownAction mouseup->p-everywhere#mouseUpAction",
	      });
				$('#minefield>div:nth-child(' + (i + 1) + ')').append(sectorDiv);
			};
		};
		$('#minefield').css({"height" : (this.minefield.length*38 + "px"),"width" : (this.minefield[0].length*38 + "px")});
		this.setBombCount();
		$('#timer_div > p').text( '000 seconds');
		clearInterval(this.timerInterval);
		$('#main-content').height('100%')
  }

	endGame(theSector) {				
		for (let i = 0; i < this.minefield.length; i++) {
			for (let j = 0; j < this.minefield[i].length; j++) {
				let $thediv = $("#minefield>.p-everywhere_grid-x>.sector[x='" + this.minefield[i][j].x + "'][y='" + this.minefield[i][j].y + "']");
				if (this.minefield[i][j].isBomb === true && this.minefield[i][j].isFlagged === 0) {
					$thediv.css({'border-style': 'inset', 'border-color':'lightgrey', 'font-size':'3em', 'line-height' : '42px', 'color':'black', 'background-color':'red'});
					$thediv.text('*');
				} else if (this.minefield[i][j].isBomb === false && this.minefield[i][j].isFlagged === 1){
					$thediv.css({'border-style': 'inset', 'border-color':'lightgrey', 'color':'black', 'background-color':'red'});
					$thediv.text('X');
				};
			};
		};
		theSector.css({'border-style': 'inset', 'border-color':'lightgrey', 'font-size':'2em', 'line-height' : '28px', 'color':'black', 'background-color':'red'});
		theSector.text('O');
		this.gameOver();
	};

	wonGame() {
		let closed = 0;
		for (let i = 0; i < this.minefield.length; i++) {
			for (let j = 0; j < this.minefield[i].length; j++) {
				if (this.minefield[i][j].isBomb === false && this.minefield[i][j].opened === false){
					closed++;
				};
			};
		};
		if (closed > 0){
			return false;
		} else {
			for (let i = 0; i < this.minefield.length; i++) {
				for (let j = 0; j < this.minefield[i].length; j++) {
					if (this.minefield[i][j].isBomb === true && this.minefield[i][j].isFlagged === 0 || this.minefield[i][j].isFlagged === 2){
						this.flagToggle(this.minefield[i][j]);
					};
				};
			};
			// this.calculateDificulty();
			this.youWin();
		};
	};

	youWin() {
		$('#emoji-status').text(this.emojiState['cool'])
		this.gameRunning = false;
		clearInterval(this.timerInterval);
		this.lockBoard()
	};

	gameOver() {
		$('#emoji-status').text(this.emojiState['dead'])
		this.gameRunning = false;
		clearInterval(this.timerInterval);
		this.lockBoard()
	};

	lockBoard() {
		console.log('locking board')
		$('.sector').removeAttr('data-action')
	}

	postTime() {
		$('#result h2').text('Time : ' + this.timeElapsed().toString() + ' seconds');
		$('#result h3').text('Difficulty : ' + this.difficulty.toFixed(2).toString());
	};

	timeElapsed() {
		let nowTime = new Date();
		let differenceTime = Math.round((nowTime.getTime() - this.startTime.getTime()) / 1000);
		return differenceTime;
	};

	bombCounter(number) {
		if (number > 0) {
			return true;
		} else {
			return false;
		};
	}

	setBombCount() {
		$("#bomb_counter").text("bombs left: " + this.bombCount)
	}

	startTheGame() {
		this.createTheField(settings);
		this.hideSettings();
		$('#emoji-status').text(this.emojiState['happy'])
	};



  ////////////////////////////
  /////  Game Logic  //////
  ////////////////////////////


	startTheTimer() {
		this.startTime = new Date();
		this.gameRunning = true;
		this.timerInterval = setInterval(() => {this.displayTimer()} , 1000);
	}

	displayTimer() {
		let timeToDisplay = this.timeElapsed().toString();
		$('#timer_div > p').text( "000".substring(0, 3 - timeToDisplay.length) + timeToDisplay + ' seconds');
	}

	timeElapsed() {
		let nowTime = new Date();
		let differenceTime = Math.round((nowTime.getTime() - this.startTime.getTime()) / 1000);
		return differenceTime;
	};

	findSector(x,y) {
		return this.minefield[x][y];
	}

	checkNeighborBombCount(thisSector,activeDiv) {
		if (thisSector.opened === true) {
			let data = this.checkNeighborBombFlag(thisSector);


			if (data.flags === data.bombs){
				console.log('checkNeighborBombCount')
				this.openNeighbors(thisSector);
			};
		} else {
			this.resetDiv(activeDiv);
		};
	};

	checkNeighborBombFlag(thisSector) {
		let flagNumber = 0;
		let bombNumber = 0;
		let neighbors = this.findNeighbors(thisSector);
		for (let i = 0; i < neighbors.length; i++) {
			if (this.minefield[neighbors[i][0]][neighbors[i][1]].isBomb === true) {
				bombNumber++;
			};
			if (this.minefield[neighbors[i][0]][neighbors[i][1]].isFlagged === 1) {
				flagNumber++;
			};
		};
		return {'flags':flagNumber,'bombs':bombNumber};
	};

	openNeighbors(thisSector) {
		let neighbors = this.findNeighbors(thisSector);
		let neighborsObjects = this.findneighborsObjects(neighbors);
		for (let i = 0; i < neighborsObjects.length; i++) {
			let $thediv = $("#minefield>.p-everywhere_grid-x>.sector[x='" + neighborsObjects[i].x + "'][y='" + neighborsObjects[i].y + "']");
			if (neighborsObjects[i].isFlagged === 1 || neighborsObjects[i].opened === true){
			} else if (neighborsObjects[i].isBomb === true) {
				this.endGame($thediv);
			} else {
				neighborsObjects[i].opened = true;
				let thisDivsNumber = this.checkNeighbor(neighborsObjects[i]);
	
				$thediv.text(thisDivsNumber);
				this.activateDiv($thediv);
				this.setDivColor($thediv, this.colorKey[thisDivsNumber]);
			};
		};
	};

	findneighborsObjects(neighbors) {
		let theObjects = [];
		for (let i = 0; i < neighbors.length; i++) {
			theObjects.push(this.minefield[neighbors[i][0]][neighbors[i][1]]);
		};
		return theObjects;
	};

	checkNeighbor(sector) {
		let bombNumber = 0;
		let neighbors = this.findNeighbors(sector);
		for (let i = 0; i < neighbors.length; i++) {
			if (this.minefield[neighbors[i][0]][neighbors[i][1]].isBomb === true) {
				bombNumber++;
			};
		};
		console.log(bombNumber)
		if (bombNumber === 0) {
			console.log('it zero!')
			this.openNeighbors(sector);
			return '';
		} else {
			sector.bombNear = bombNumber;
			return bombNumber;
		};
	};

	findNeighbors(sector) {
		let x = sector.x;
		let y = sector.y;
		let neighbors = [[x+1,y-1],[x+1,y],[x+1,y+1],[x,y-1],[x,y+1],[x-1,y-1],[x-1,y],[x-1,y+1]];
		let realNeighbors = neighbors.filter( (item) => {
		    return (item[0] >= 0 && item[0] < this.minefield.length && item[1] >= 0 && item[1] < this.minefield[0].length);
		});
		return realNeighbors;
	};

  ////////////////////////////
  /////  Game Controls  //////
  ////////////////////////////


	setControls() {
		$('#minefield').on("contextmenu", function(){return false;})
	};

	mouseDownAction(event) {
		event.preventDefault();
		if ( this.gameRunning === false ) {
			this.startTheTimer()
		}
		if (event.which === 1) {
			this.leftButtonDown = true;
		$('#emoji-status').text(this.emojiState['scared'])
			this.clickStatus(101)
			if (this.rightButtonDown == true) {
				this.bothButtonDown = true
			this.clickStatus(102)
			};
			let thisSector = this.findSector(this.currentDiv.getAttribute('x'), this.currentDiv.getAttribute('y'));
			if (thisSector.opened === false) {
				if (thisSector.isFlagged === 0 || thisSector.isFlagged === 2) {
					this.activateDiv(this.currentDiv);
				};
			};
		} else if (event.which === 3) {
			this.rightButtonDown = true;
			this.clickStatus(103)
			if (this.leftButtonDown == true) {
				this.bothButtonDown = true
			this.clickStatus(104)
			};
		};
	};

	mouseUpAction(event) {
		event.preventDefault();
		let activeDiv = event.currentTarget;
		let activeDivX = activeDiv.getAttribute('x')
		let activeDivY = activeDiv.getAttribute('y')
		let thisSector = this.findSector(activeDivX, activeDivY);
		if (event.which === 1) {
			if (this.bothButtonDown === true && this.rightButtonDown === true) {
				this.leftButtonDown = false;
				this.clickStatus(1)
			} else if (this.bothButtonDown === true && this.rightButtonDown === false) {
				this.checkNeighborBombCount(thisSector,activeDiv);
				this.leftButtonDown = false;
				this.bothButtonDown = false;
			} else {
				if (thisSector.isBomb === true && thisSector.isFlagged !== 1) {
					this.endGame($(activeDiv));
					this.clickStatus(2)
				} else {
					thisSector.opened = true;
					console.log('here')
					this.activateDiv($(activeDiv));
					this.setDivColor($(activeDiv), this.colorKey[this.checkNeighbor(thisSector)]);
					$(activeDiv).text(this.checkNeighbor(thisSector));
					this.clickStatus(3)
					$('#emoji-status').text(this.emojiState['happy'])
				};
				this.leftButtonDown = false;
				this.clickStatus(4)
			};
		} else if (event.which === 3) {
			if (this.leftButtonDown === true) {
	
				this.bothButtonDown = true;
				this.rightButtonDown = false;
				$('#emoji-status').text(this.emojiState['happy'])
				this.clickStatus(5)
			} else if (this.bothButtonDown === true) {
	
				this.checkNeighborBombCount(thisSector,activeDiv);
				this.bothButtonDown = false;
				this.rightButtonDown = false;
				$('#emoji-status').text(this.emojiState['happy'])
				this.clickStatus(6)
			} else {
				this.flagToggle(thisSector);
				this.rightButtonDown = false;
				this.clickStatus(7)
			};
		};
		this.wonGame()
	};

	clickStatus(eventNumber) {
		console.log('BREAK ----------------')
		console.log('Event : ' + eventNumber)
		console.log("right ButtonDown = " + this.rightButtonDown)
		console.log("left ButtonDown = " + this.leftButtonDown)
		console.log("both ButtonDown = " + this.bothButtonDown)
	}

	mouseOverCheck(event) {
		if (this.currentDiv) {
			this.oldDiv = this.currentDiv;
			this.currentDiv = event.currentTarget;
			let thisSector = this.findSector(this.oldDiv.getAttribute('x'),this.oldDiv.getAttribute('y'));
			if (!thisSector.opened){
				this.resetDiv(this.oldDiv);
			};
			if (this.leftButtonDown) {
				this.activateDiv(this.currentDiv);
			};
		} else {
			this.currentDiv = event.currentTarget;
		}
	};

	flagToggle(thisSector) {
		let $thediv = $("#minefield>.p-everywhere_grid-x>.sector[x='" + thisSector.x + "'][y='" + thisSector.y + "']");
		if (thisSector.isFlagged === 0 && thisSector.opened === false) {
			thisSector.isFlagged = 1;
			$thediv.css('color', 'red');
			$thediv.text('P');
			this.bombCount--;
			this.setBombCount();
			// resetDiv($currentDiv);
		} else if (thisSector.isFlagged === 1 && thisSector.opened === false) {
			thisSector.isFlagged = 2;
			$thediv.css('color', 'orange');
			$thediv.text('?');
			this.bombCount++;
			this.setBombCount();
			// resetDiv($currentDiv);
		} else if (thisSector.isFlagged === 2 && thisSector.opened === false) {
			thisSector.isFlagged = 0;
			$thediv.css('color', 'black');
			$thediv.text('');
			// resetDiv($currentDiv);
		};
	};

	mouseOutCheck(event) {
	};

	resetDiv(theDiv) {
		$(theDiv).css({'border-style': 'outset', 'border-color':'grey'});
	};

	activateDiv(theDiv) {
		$(theDiv).css({'border-style': 'inset', 'border-color':'lightgrey'});
	};

	setDivColor(theDiv, color) {
		$(theDiv).css({ 'color':color });
	};
}
